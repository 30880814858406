import React, {  } from 'react'
import styled, {  } from "styled-components"
import Flex, {  } from "styled-flex-component"
import breakpoint from "styled-components-breakpoint"
import _ from "lodash"
import { GrNext as NextIcon, GrPrevious as PrevIcon } from "react-icons/gr"
import { ArrowButton } from 'src/components'
import { BreakLine, useIsDeviceWidth } from 'src/components/utils'

const CONTENT_WIDTH = 740

// 날짜 포맷팅 함수
const formatDate = (dateString) => {
    if (!dateString) return '';
    const date = new Date(dateString);
    const year = date.getFullYear();
    const month = String(date.getMonth() + 1).padStart(2, '0');
    const day = String(date.getDate()).padStart(2, '0');
    return `${year}.${month}.${day}`;
}

const ArticleRoot = styled(Flex)`
    max-width: 1000px;
    margin: auto;
    padding: 200px 20px 100px;
    box-sizing: border-box;

    color: ${props => props.theme.primary};
    font-size: 16px;

    h1, h2, h3, h4, h5, h6 {
        font-weight: bold;
        font-size: 24px;
        margin-bottom: 20px;
    }
    a {
        padding: 10px;
        border-radius: 10px;
        transition: all 0.2s;
        :hover {
            opacity: 0.5;
        }
    }
    p {
        line-height: 1.6;
        margin-bottom: 30px;
    }
    strong {
        font-weight: bold;
    }
    img {
        max-width: 100%;
        border-radius: 20px;
        margin-bottom: 40px;
        object-fit: cover;
    }
    figure {
        display: flex;
        flex-direction: column;
        justify-content: center;
        align-items: center;
    }

    .has-text-align-center {
        text-align: center;
    }

    .has-large-font-size {
        font-size: bold;
    }

    ${breakpoint("mobile", "big desktop")`
        padding 100px 20px;
    `}
`
const Title = styled(Flex)`
    max-width: ${CONTENT_WIDTH}px;
    text-align: center;

    h1 {
        font-size: 40px;
    }
    time {
        color: ${props => props.theme.secondary};
        font-size: 20px;
        font-weight: bold;
    }
    ${breakpoint("mobile", "big desktop")`
        h1 {
            font-size: 24px;
        }
        time {
            font-size: 16px;
        }
    `}
`
const Category = styled.div`
    font-size: 12px;
    font-weight: bold;
    width: fit-content;
    padding: 5px;
    border: 1px solid ${props => props.theme.primary};
    border-radius: 20px;
    margin-bottom: 20px;
`
const Content = styled.div`
    max-width: ${CONTENT_WIDTH}px;
    
    /* 특정 이미지만 조정 */
    img.wp-image-2625 {
        max-width: 100% !important;
        width: auto !important;
        margin: 0 auto 40px !important;
        display: block !important;
    }
`
const Buttons = styled(Flex)`
    width: 100px;
`

export default function Article({ pageContext, navigate, uri }) {
    const { title, categories, content, date, prevUrl, nextUrl } = pageContext
    const category = categories && categories[0] ? categories[0].name : ""
    const backUrl = _.dropRight(uri.split("/"), 1).join("/")
    const isSmallDevice = useIsDeviceWidth({ from: 0, to: 800})
    
    // 날짜 포맷팅 적용
    const formattedDate = formatDate(date)

    // prev & next buttons
    const prevButtonRender = <PrevIcon
    onClick={e => navigate(prevUrl)}
    size={30}
    style={{
        cursor: prevUrl ? "pointer" : "initial",
        opacity: prevUrl ? 1 : 0.3
    }}/>
    const nextButtonRender = <NextIcon
    onClick={e => navigate(nextUrl)}
    size={30}
    style={{
        cursor: nextUrl ? "pointer" : "initial",
        opacity: nextUrl ? 1 : 0.3
    }}/>

    // arrow button
    const arrowButtonRender = <ArrowButton
    onClick={e => { navigate(backUrl)} }
    position={{
        left: isSmallDevice ? 20 : 70,
        top: isSmallDevice ? 20 : 70,
    }}
    animationPlay={true}
    direction="left"/>


    return <>
        <ArticleRoot center column>
            <Title center column>
                <Category>{category}</Category>
                <h1>{title}</h1>
                <time>{formattedDate}</time>
            </Title>
            <BreakLine paddingTop={60} paddingBottom={60}/>
            <Content dangerouslySetInnerHTML={{__html: content}} />
            <BreakLine paddingTop={40} paddingBottom={50}/>
            <Buttons justifyBetween>
                {prevButtonRender}
                {nextButtonRender}
            </Buttons>
        </ArticleRoot>

        {arrowButtonRender}
    </>
}